import React, { useContext, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import Notify from "../../notifications/Notify";
import FileSelect from "./FileSelect";
import { FileContext } from "../FileContext";
import { IR_FILE_UPLOAD_STATUS, IR_UPLOAD_STATUS_MODEL_NAME, UPLOAD_FILE_PROPERTIES } from "../../../constants";
import { DATA_UPLOAD_URL } from "../../../gql/landingapi/queries";
import { CREATE_IR_UPLOAD_STATUS } from "../../../gql/landingapi/mutations";
import languageEncoding from "detect-file-encoding-and-language";

const UploadComponent = ({ buttonContent, client, email, targetModule, isActionButtonDisabled }) => {
  const { file, setFile } = useContext(FileContext);

  /**
   * This method is used to get the presigned url for a file to be uploaded to S3
   * @param {String} fileName Pass the file name of the file that is uploaded
   * @returns the presignedUrl Data form S3
   */
  const fetchPresignedUrl = async (fileName, encoding = null) => {
    const contentTypeObj = {
      "Content-Type": `${UPLOAD_FILE_PROPERTIES.allowedContentType}`,
      charset: `${encoding}`
    };
    const stringifyObj = JSON.stringify(contentTypeObj);
    try {
      const presignedUrlData = await client.query({
        query: DATA_UPLOAD_URL,
        fetchPolicy: "no-cache",
        variables: {
          targetModule: targetModule,
          fileName,
          contentType: stringifyObj
        }
      });

      if (!presignedUrlData?.data && presignedUrlData?.data?.dataUploadUrl) {
        throw new Error("File could not be loaded");
      }

      return presignedUrlData?.data?.dataUploadUrl;
    } catch (error) {
      throw new Error("File could not be loaded");
    }
  };

  /**
   * This method is used to upload the selected file to the given presigned url
   * @param {String} presignedUrl the presigned url to which the file has to be uploaded
   * @param {Object Blob} fileData the selected file
   * @returns
   */
  const uploadFileToS3 = async (presignedUrl, fileData) => {
    try {
      const uploadHeaders = new Headers({
        "x-amz-server-side-encryption": "AES256"
      });
      const fileUploadedToS3Data = await fetch(presignedUrl, {
        method: "PUT",
        headers: uploadHeaders,
        body: fileData
      });
      if (!fileUploadedToS3Data && !fileUploadedToS3Data?.ok) {
        throw new Error("File could not be loaded");
      }

      return fileUploadedToS3Data;
    } catch (error) {
      throw new Error("File could not be loaded");
    }
  };

  /**
   * This method calls a mutation to create an enrty for the uploaded file in submitted status
   * @param {String} fileName the name of the file that has been uploaded
   * @param {String} s3VersionId the version id of the file uploaded in to s3
   * @returns success response for the entry of the file status
   */
  const updateUploadStatus = async (fileName, s3VersionId) => {
    try {
      const fileUploadStatus = await client.mutate({
        mutation: CREATE_IR_UPLOAD_STATUS,
        fetchPolicy: "no-cache",
        variables: {
          email: email,
          fileName: fileName,
          modelName: IR_UPLOAD_STATUS_MODEL_NAME,
          s3VersionId: s3VersionId,
          status: IR_FILE_UPLOAD_STATUS[0]?.statusKey,
          targetModule: targetModule,
          uploadDate: new Date().toISOString()
        }
      });
      if (!fileUploadStatus) {
        throw new Error("File could not be loaded");
      }
      return fileUploadStatus;
    } catch (error) {
      throw new Error("File could not be loaded");
    }
  };

  /**
   * This method is used to upload file to s3 and make an entry to status log table
   * @param {Object Blob} file the selected file
   */

  const onFileSelect = async (file) => {
    setFile(file);
    try {
      const fileInfoObj = await languageEncoding(file).then((fileInfo) => {
        return fileInfo;
      });

      const timeStamp = Date.now();
      const fileNameWithTimeStamp = timeStamp + "-" + file.name;

      const presignedUrlData = await fetchPresignedUrl(fileNameWithTimeStamp, fileInfoObj?.encoding);
      if (!presignedUrlData?.presignedUrl) {
        throw new Error("File could not be loaded");
      }

      const fileUploadedToS3Data = await uploadFileToS3(presignedUrlData?.presignedUrl, file);

      if (!fileUploadedToS3Data?.ok) {
        throw new Error("File could not be loaded");
      }
      setFile(null);

      await updateUploadStatus(fileNameWithTimeStamp, presignedUrlData?.s3VersionId);
    } catch (error) {
      onFailedRequirements(error);
    }
  };

  /**
   * This method is called as an callback if any of the upload calls fails
   */
  const onFailedRequirements = useCallback((error) => {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: error?.message || error
    });
  }, []);

  /**
   * To check file extension is valid
   * @param fileName the file name of the file uploaded
   * @returns true/false flag for validation
   */
  const isValidFile = (fileName) => {
    if (!UPLOAD_FILE_PROPERTIES.allowedExtensions.exec(fileName)) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * To check image size is valid
   * @param bytes bytes size of the pdf
   * @returns true/false flag for validation
   */
  const isValidSize = (size) => {
    if (size) {
      let fileSize = size / Math.pow(1024, 2);
      if (fileSize <= UPLOAD_FILE_PROPERTIES.allowedSize) {
        return true;
      }
    }
    return false;
  };

  const validateFileForUpload = (selectedFile) => {
    const fileName = selectedFile?.name;
    const fileSize = selectedFile?.size;
    if (!isValidFile(fileName)) {
      throw new Error("Invalid file format.");
    }
    if (!isValidSize(fileSize)) {
      throw new Error("File is too large. Max limit is 5MB only.");
    }
  };

  return (
    <FileSelect
      accept={UPLOAD_FILE_PROPERTIES.allowedFileType}
      fileValidate={validateFileForUpload}
      onFileSelect={onFileSelect}
      renderContent={buttonContent}
      onFailedRequirements={onFailedRequirements}
      shouldClear={file === null}
      disableInput={isActionButtonDisabled}
    />
  );
};

const mapStateToProps = (store) => ({
  email: store?.user?.email
});

export default compose(connect(mapStateToProps, {}), withApollo)(UploadComponent);
